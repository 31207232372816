<template>
    <card head foot :body="false">
        <template #header>
            <div class="card-header d-flex justify-content-between">
                <h4>{{ $t('admin.exports.title') }}</h4>
                <btn outline is-router-link :to="{ name: 'admin.exports.create' }">
                    <fa icon="plus" class="mr-1"></fa>
                    {{ $t('admin.exports.create') }}
                </btn>
            </div>
        </template>
        <data-table :data="dataExports" :columns="columns" class="table-responsive">
            <template #cell-edit="{ rowData }">
                <div class="p-2">
                    <!-- <btn v-if="rowData.class_name.endsWith('\\CustomExport')" outline is-router-link
                        :to="{ name: 'admin.exports.edit', params: { id: rowData.id } }">
                        <fa icon="edit" class="mr-1"></fa>
                        {{ $t('admin.exports.columns.edit') }}
                    </btn> -->
                </div>
            </template>
            <template #cell-download="{ rowData }">
                <div class="p-2">
                    <btn v-if="!rowData.scheduled" outline @click.native="downloadExport({ export_id: rowData.id })">
                        <fa icon="download" class="mr-1"></fa>
                        {{ $t('admin.exports.columns.download') }}
                    </btn>
                    <btn :disabled="scheduledExportGenerated(rowData.id)" v-else-if="rowData.scheduled" outline
                        @click.native="scheduleExport({ export_id: rowData.id })">
                        <fa icon="clock" class="mr-1"></fa>
                        {{ $t('admin.exports.columns.scheduled') }}

                    </btn>
                </div>
            </template>
        </data-table>
    </card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: "exports",
    data() {
        return {

        }
    },
    computed: {
        ...mapState('resources/exports', ['dataExports', 'generatedExports']),
        columns() {
            return [
                { name: 'name', label: this.$t('admin.exports.columns.name') },
                { name: 'class_name', label: this.$t('admin.exports.columns.class_name') },
                { name: 'description', label: this.$t('admin.exports.columns.description') },
                { name: 'edit', label: this.$t('admin.exports.columns.edit'), width: 'min-content' },
                { name: 'scheduled', label: this.$t('admin.exports.columns.scheduled'), width: 'min-content' },
                { name: 'download', label: this.$t('admin.exports.columns.download'), width: 'max-content' },
            ]
        },
    },
    methods: {
        ...mapActions('resources/exports', ['getExports', 'downloadExport', 'scheduleExport']),
        scheduledExportGenerated(exportId) {
            var exportTask = this.dataExports.find(exportTask => exportTask.id == exportId)
            if (exportTask) {
                return exportTask.scheduled && this.generatedExports.find(e => e.export_id == exportId && e.user_id == this.$store.state.auth.user.id && e.status == 'scheduled') ||
                    exportTask.generated_exports.find(e => e.user_id == this.$store.state.auth.user.id && e.status == 'scheduled') != undefined
            }
        }
    },
    mounted() {
        this.getExports()
    }
}
</script>

<style lang="scss" scoped></style>