<template>
    <div class="node card" :class="{ selected: data.selected }" :style="nodeStyles" data-testid="filter-node">
        <div class="d-flex">
            <div class="card-body">
                <div class="card-title">{{ data.label }}</div>
            </div>
            <!-- <template v-for="output in outputs()">
                <Ref :key="data.data.name + '_output'" class="output-socket" :emit="emit" :data="{
                    type: 'socket',
                    side: 'output',
                    key: 'filter_' + data.data.key + '_output',
                    nodeId: data.id,
                    payload: output.socket,
                }" data-testid="output-socket" />
            </template> -->
        </div>
        <!-- <div v-if="Object.keys(filter).length > 0 && !Object.keys(filter).includes('parameters')"
            class="d-flex align-items-center">
            <div v-for="(opFilter, op, opIndex) in filter" :key="filter.name + op" class="d-flex align-items-center">
                <label class="me-2">
                    {{ filter.name }}
                </label>
                <dropdown btn-outline :items="filterOperators" :value="op"
                    @input="(v) => changeFilterOperator(v, filter.name, opIndex)" class="mx-2" @pointerdown.stop="">
                    <template v-slot:btn="{ toggleDropdown }">
                        <div class="btn" @click.stop="toggleDropdown" @pointerdown.stop="">
                            {{ opFilter.label }}
                        </div>
                    </template>
                </dropdown>
                <scope v-if="opFilter.parameters" :filterModel="findFilterFromKeyList(filter.name)"
                    v-slot="{ filterModel }" class="d-flex">
                    <template v-for="(param, pIndex) in opFilter.parameters">
                        <scope :el="suitableComponent(filterModel, param, pIndex, op, opIndex)"
                            :key="filter.name + op + 'params-' + pIndex" v-slot="{ el }">
                            <component :is="el.name" class="me-2" v-bind="el.bindings" v-on="el.events" />
                        </scope>
                    </template>

                </scope>
            </div>
        </div>
        <div v-if="Object.keys(filter).includes('parameters')" class="d-flex align-items-center">
            <label class="me-2">
                {{ findFilterFromKeyList(filter.name).name }}
            </label>
            <scope v-if="filter.parameters" :filterModel="findFilterFromKeyList(filter.name)" v-slot="{ filterModel }"
                class="d-flex align-items-center">
                <template v-for="(param, pIndex) in filter.parameters">
                    <scope :el="suitableComponent(filterModel, param, pIndex)" v-slot="{ el }" class="d-flex"
                        :key="filter.name + 'params-' + pIndex">
                        <component :is="el.name" class="me-2" v-bind="el.bindings" v-on="el.events" />
                    </scope>
                </template>
            </scope>
        </div> -->
    </div>
</template>

<script>
import { Ref } from 'rete-vue-plugin/vue2'
import queryBuilderMixin from "~/mixins/query-builder";
function sortByIndex(entries) {
    entries.sort((a, b) => {
        const ai = a[1] && a[1].index || 0
        const bi = b[1] && b[1].index || 0

        return ai - bi
    })
    return entries
}

export default {
    props: ['data', 'emit', 'seed'],
    mixins: [queryBuilderMixin],
    mounted() {
        // console.log('mounted', this.data, this.$store)
    },
    components: {
        Ref
    },

    computed: {
        filter() {
            return this.data?.data
        },
        nodeStyles() {
            return {
                width: Number.isFinite(this.data.width) ? `${this.data.width}px` : '',
                height: Number.isFinite(this.data.height) ? `${this.data.height}px` : ''
            }
        },
        queryBuilder() {
            return this.data.queryBuilder
        },
        componentDefinitions() {
            return {
                checkbox: {
                    name: 'checkbox',
                    bindings: { trueValue: true, falseValue: false },
                },
                dropdown: {
                    name: 'dropdown',
                    bindings: {
                        valueName: 'id',
                        labelName: 'name',
                        btnOutline: true
                    }
                },
                textbox: {
                    name: 'textbox',
                    bindings: {}
                },
                number: {
                    name: 'number',
                    bindings: {}
                },
                datepicker: {
                    name: 'datepicker',
                    bindings: {}
                },


            }
        },

    },
    methods: {
        inputs() {
            return sortByIndex(Object.entries(this.data.inputs))
        },
        controls() {
            return sortByIndex(Object.entries(this.data.controls))
        },
        outputs() {
            return sortByIndex(Object.entries(this.data.outputs))
        },
        fieldHasInput(field) {
            if (this.data.inputs) {
                var inputName = this.data.data.table + '.' + field.name + '_input'
                return this.data.inputs[inputName] !== undefined
            }
        },
        fieldInput(field) {
            if (this.data.inputs) {
                var inputName = this.data.data.table + '.' + field.name + '_input'
                return this.data.inputs[inputName]
            }
        },
        fieldHasOutput(field) {
            if (this.data.outputs) {
                var outputName = this.data.data.table + '.' + field.name + '_output'
                // console.log(outputName, this.data.outputs, this.data.outputs[outputName], this.data.inputs)
                return this.data.outputs[outputName] !== undefined
            }
            // var outputName = this.data.data.table + '.' + field.name
            // return this.data.outputs[outputName] !== undefined
        },
        fieldOutput(field) {
            if (this.data.outputs) {
                var outputName = this.data.data.table + '.' + field.name + '_output'
                return this.data.outputs[outputName]
            }
            // var outputName = this.data.data.table + '.' + field.name
            // return this.data.outputs[outputName]
        }
    },


}
</script>

<style lang="scss" scoped>
@use "sass:math";

@import "./vars";

.node {
    user-select: none;

    &.selected {
        --bs-card-border-color: var(--bs-info);
    }

    //min-width: $node-min-width;
    // background: black;
    // border: 2px solid grey;
    // border-radius: 10px;
    // cursor: pointer;
    // box-sizing: border-box;
    // width: $node-width;
    // height: auto;
    // padding-bottom: 6px;
    position: relative;
    // user-select: none;

    // &:hover {
    //     background: #333;
    // }

    // &.selected {
    //     border-color: red;
    // }

    .title {
        color: white;
        font-family: sans-serif;
        font-size: 18px;
        padding: 8px;
    }

    .output {
        text-align: right;
    }

    .input {
        text-align: left;
    }

    .output-socket {
        text-align: right;
        margin-right: -1px;
        display: inline-block;
    }

    .input-socket {
        text-align: left;
        margin-left: -1px;
        display: inline-block;
    }

    .input-title,
    .output-title {
        vertical-align: middle;
        color: white;
        display: inline-block;
        font-family: sans-serif;
        font-size: 14px;
    }

    .input-control {
        z-index: 1;
        vertical-align: middle;
        display: inline-block;
    }

    .control {}
}
</style>