<template>
    <div>
        <div class="toolbar">
            <btn-group class="me-2">
                <btn outline v-model="layoutSettings['elk.direction']" true-value="UP">Up</btn>
                <btn outline v-model="layoutSettings['elk.direction']" true-value="DOWN">Down</btn>
                <btn outline v-model="layoutSettings['elk.direction']" true-value="LEFT">Left</btn>
                <btn outline v-model="layoutSettings['elk.direction']" true-value="RIGHT">Right</btn>
            </btn-group>
            <btn-group class="me-2">
                <btn outline v-model="layoutSettings['elk.algorithm']" true-value="layered">Layered</btn>
                <btn outline v-model="layoutSettings['elk.algorithm']" true-value="force">Force</btn>
                <btn outline v-model="layoutSettings['elk.algorithm']" true-value="mrtree">Mrtree</btn>
                <btn outline v-model="layoutSettings['elk.algorithm']" true-value="radial">Radial</btn>
            </btn-group>
            <btn-group>
                <btn outline v-model="layoutSettings['elk.layered.nodePlacement.strategy']" true-value="INTERACTIVE">
                    Interactive</btn>
                <btn outline v-model="layoutSettings['elk.layered.nodePlacement.strategy']" true-value="SIMPLE">Simple
                </btn>
                <btn outline v-model="layoutSettings['elk.layered.nodePlacement.strategy']"
                    true-value="LINEAR_SEGMENTS">Linear Segments</btn>
                <btn outline v-model="layoutSettings['elk.layered.nodePlacement.strategy']" true-value="SPLINES">Splines
                </btn>
            </btn-group>
            <!-- elk.edgeRouting -->
            <btn-group>
                <btn outline v-model="layoutSettings['elk.edgeRouting']" true-value="POLYLINE">Polyline</btn>
                <btn outline v-model="layoutSettings['elk.edgeRouting']" true-value="SPLINES">Splines</btn>
                <btn outline v-model="layoutSettings['elk.edgeRouting']" true-value="ORTHOGONAL">Orthogonal</btn>
                <btn outline v-model="layoutSettings['elk.edgeRouting']" true-value="ORGANIC">Organic</btn>
            </btn-group>
        </div>
        <div ref="editorContainer" class="rete-editor"></div>
    </div>
</template>

<script>
import queryBuilderMixin from "~/mixins/query-builder";
import { createEditor, addNodes, addConnections, resetView, addFilterNodes } from "./rete/editor";
import { mapState } from "vuex";
export default {
    name: "ReteEditor",
    props: {
        nodes: {
            type: Array,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
        query: {
            type: Object,
            required: true,
        },
    },
    mixins: [queryBuilderMixin],
    data() {
        return {
            editor: null,
            area: null,
            arrange: null,
            addingNodes: false,
            addingConnections: false,
            layout: null,
            layoutSettings: {
                "elk.algorithm": "mrtree",
                "elk.direction": "RIGHT",
                "elk.spacing.nodeNode": "50",
                "elk.spacing.edgeNode": "60",
                "elk.layered.spacing.nodeNodeBetweenLayers": "50",
                "elk.layered.nodePlacement.strategy": "INTERACTIVE",
                "elk.layered.thoroughness": "1000",
                "elk.nodeSize.constraints": "NODE_LABELS PORTS PORT_LABELS MINIMUM_SIZE",
                "elk.edgeRouting": "ORTHOGONAL",
                "elk.graphviz.adaptPortPositions": "true",
                "elk.alg.force.options.ForceModelStrategy": "FRUCHTERMAN_REINGOLD",
                "elk.alg.graphviz.dot.transform.NeatoModel": "SHORTPATH",
            }
        };
    },
    async mounted() {
        const container = document.querySelector(".rete-editor")
        // if (this.queryBuilderLoaded)
        this.createReteEditor();
        // await addNodes(editor, area, this.nodes, this.query);
        // await addConnections(editor, this.links);
        // await resetView(editor, area, arrange, this.nodes);

    },
    destroyed() {
        this.area && this.area.destroy();
    },
    computed: {
        ...mapState('resources/QueryBuilder', ['queryBuilder', 'queryBuilderLoaded', 'queryBuilderLoading']),
        filterNodes() {
            var filterNodes = [];
            // merge with filterObj by name
            for (var key in this.query.filters) {
                var filter = this.query.filters[key];
                var filterObj = this.keyList.find((f) => f.key == key);
                if (key.includes(".")) {
                    // filter is on relation
                    var relation = key.split(".")[0];
                    var relationObj = this.keyList.find((f) => f.key == relation);
                    var rel = this.findRelation(relation)
                    if (!rel) continue;
                    var found = filterNodes.find((f) => f.target == rel.model);
                    if (found) {
                        found.filters.push({
                            ...filter, ...filterObj, key
                        })


                    } else {
                        filterNodes.push({
                            target: rel.model, filters: [{
                                ...filter, ...filterObj, key
                            }]
                        });

                    }

                } else {
                    // filter is on main model
                    var target = this.queryBuilder.model
                    var found = filterNodes.find((f) => f.target == target);
                    if (found) {
                        found.filters.push({ key, ...filter, props: filterObj })
                    }
                    else {
                        filterNodes.push({ target, filters: [{ key, ...filter, props: filterObj }] });

                    }
                }
                // instead i'd like filters grouped by relation

                if (filterObj) {

                    // filterNodes.push({ ...filter, ...filterObj });
                }
            }
            return filterNodes;
        }
    },
    methods: {
        async createReteEditor() {
            // console.log('Creating rete editor');
            const container = document.querySelector(".rete-editor")
            const { editor, area, arrange, layout } = await createEditor(container);
            this.editor = editor;
            this.area = area;
            this.arrange = arrange;
            this.layout = layout;
        },
        async addNodes() {
            // console.log('Adding nodes', this.nodes);
            this.addingNodes = true;
            await addNodes(this.editor, this.area, this.nodes, this.query)
            let nodes = this.editor.getNodes();
            console.log('Nodes', nodes);
            this.addingNodes = false;

            await this.addFilterNodes()
            await this.addConnections()
            await this.resetView();

            // await addConnections(this.editor, this.links);
            // await this.addFilterNodes();
        },
        async addFilterNodes() {
            // console.log('Adding filter nodes', this.filterNodes);
            await addFilterNodes(this.editor, this.area, this.nodes, this.filterNodes);
        },
        async addConnections() {
            this.addingConnections = true;
            await addConnections(this.editor, this.links, this.filterNodes, this.queryBuilder);
            this.addingConnections = false;
        },
        async resetView() {
            return await this.layout(true, this.layoutSettings);
        },
        async clearNodes() {
            console.log('Clearing nodes');
            return await this.editor.clear();
        },
        findRelation(key) {
            if (!this.queryBuilder.relationships) return null;
            var rel = this.queryBuilder.relationships.find(rel => rel.name === key);
            return rel;
        },
    },
    watch: {
        nodes: {
            handler: async function (newNodes) {
                if (this.editor && newNodes.length > 0) {
                    // console.log(newNodes.length, this.links.length, this.addingNodes, this.addingConnections);
                    if (!this.addingNodes) await this.addNodes()
                    // if (!this.addingConnections) this.addConnections();
                    this.resetView();
                }
            },
            deep: true,
        },
        links: {
            handler: async function (newLinks) {
                if (this.editor && newLinks.length > 0) {
                    if (!this.addingNodes) await this.addNodes();
                    // if (!this.addingConnections) this.addConnections();
                    this.resetView();
                }
            },
            deep: true,
        },
        query: {
            handler: async function (newQuery, oldQuery) {
                if (oldQuery.id && newQuery.id != oldQuery.id) {
                    this.clearNodes();
                    if (!this.addingNodes) { await this.addNodes(); }
                    // if (!this.addingConnections) this.addConnections();
                    this.resetView();

                }
                // console.log(newQuery);
                if (this.editor && newQuery) {
                    if (!this.addingNodes) await this.addNodes();
                    // if (!this.addingConnections) this.addConnections();
                    this.resetView();
                }
            },
            deep: true,
        },
        'layoutSettings': {
            handler: async function (newSettings) {
                if (this.editor && newSettings) {
                    this.resetView();
                }
            },
            deep: true,
        },
        queryBuilderLoaded: {
            handler: async function (loaded) {
                if (loaded) {
                    this.createReteEditor();
                }
            }
        }
    },

};
</script>

<style lang="scss">
.rete-editor {
    width: 100%;
    height: 1200px;
    border: 1px solid #ccc;
    position: relative;


}
</style>