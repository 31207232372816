<template>
  <questionnaire :title="$t('questionnaires.psoriasis.title')" class="p-0" :body="false">
    <template v-if="!dataitemLocked(dataitems.psoriasis_start)">
      <form-group :cols="[{ md: 6 }, { md: 6 }]" :error="errors.psoriasis_start" :di="dataitems.psoriasis_start"
        class="my-4 mx-2" label-class="fs-5">
        <input-group>
          <textbox :di="dataitems.psoriasis_start" v-model="records.psoriasis_start" v-mask="{
            alias: 'year',
            placeholder: 'yyyy',
            yearrange: { minyear: 1900, maxyear: (new Date()).getFullYear() }
          }" view-mode="decades">
          </textbox>
          <template #append>
            <div class="input-group-text" v-if="agePsoriasisStarted">
              <span
                v-html="$t('questionnaires.psoriasis.dataitems.psoriasis_start.age', { age: agePsoriasisStarted })"></span>
            </div>
          </template>
        </input-group>
      </form-group>
      <hr>
    </template>
    <fieldset class="mx-2 mb-2">
      <legend><label v-t="'questionnaires.psoriasis.type'" class="fs-5"></label></legend>
      <template v-if="PsoriasisTypes">
        <row class="g-1 card-group">
          <column :sizes="{ sm: 6, lg: 4 }" v-for="di in PsoriasisTypes" :key="'pso_type_' + di.name">
            <form-group :id="di.name" :error="errors[di.name]" class="h-100">
              <btn outline block v-model="records[di.name]" :true-value="1" :false-value="null"
                :class="['card-btn p-0']" :title="dataitems[di.name].json.image_alt">
                <row class="g-1 h-100">
                  <column :sizes="{ sm: 8, lg: 12 }">
                    <img v-if="dataitems[di.name].json && dataitems[di.name].json.image"
                      :src="'/assets/images/psoriasis/large/' + dataitems[di.name].json.image"
                      :alt="dataitems[di.name].json.image_alt" class="card-img-top img-responsive "
                      style="object-fit: cover; min-height: 200px; aspect-ratio: 4/5;" />
                    <div v-else style=" min-height: 200px; aspect-ratio: 4/5;">
                    </div>
                  </column>
                  <column :sizes="dataitems[di.name].json && dataitems[di.name].json.image ? { sm: 4, lg: 12 } : {}">
                    <div class="card-body p-2">
                      <label :for="di.name">{{ dataitems[di.name].label }}</label>
                      <fa v-if="records[di.name]" icon="check" class="mx-2" />
                    </div>
                  </column>
                </row>
              </btn>
              <form-group v-if="di.name == 'ps_type_other' && records.ps_type_other" class="mt-2"
                :error="errors.ps_type_other_specify" :di="dataitems.ps_type_other_specify">
                <div class="input-group">
                  <textbox v-clear :di="dataitems.ps_type_other_specify" v-model="records.ps_type_other_specify">
                  </textbox>
                </div>
              </form-group>
            </form-group>
          </column>
        </row>
      </template>
    </fieldset>
    <hr>

    <fieldset>
      <legend><label class="p-1 fs-5 mx-2">{{ $t('questionnaires.psoriasis.extent') }}</label></legend>
      <card ref="mapAndControls" class="mapAndControls mx-2" :body="false">
        <div class="card-header p-0 controls">
          <btn-group v-model="bodyMapGender" class="mx-1 ">
            <btn size="sm" outline true-value="Female" false-value="Male" class="p-1">{{
              $t('questionnaires.psoriasis.female') }}</btn>
            <btn size="sm" outline true-value="Male" false-value="Female" class="p-1">{{
              $t('questionnaires.psoriasis.male') }}</btn>
          </btn-group>
          <btn-group v-model="bodyMapOrientation" class="me-1">
            <btn size="sm" outline true-value="Front" false-value="Back" class="p-1">{{ $t('components.svg_map.front')
              }}
            </btn>
            <btn size="sm" outline true-value="Back" false-value="Front" class="p-1">{{ $t('components.svg_map.back') }}
            </btn>
          </btn-group>
          <btn-group>
            <btn size="sm" outline @click.native="zoomEnabled = !zoomEnabled" :class="{ active: zoomEnabled }"
              v-t="'components.svg_map.zoom'"></btn>
            <btn size="sm" outline @click.native="$refs.svgMap.resetZoom()" class="p-1"
              v-t="'components.svg_map.reset'">
            </btn>
          </btn-group>
          <btn size="sm" outline @click.native="setBodymapFullscreen" :title="$t('components.svg_map.fullscreen')"
            class="p-1 border-0 ms-1">
            <fa :icon="fullscreenActive ? 'compress' : 'expand'" size="2x" />
            <span class="sr-only" v-t="'components.svg_map.fullscreen'" />

          </btn>
          <input v-model="skintoneHex" list="skintones" type="color"
            class="d-inline-block btn p-1 ms-1 border-primary form-control form-control-sm form-control-color"
            :title="$t('questionnaires.psoriasis.choose_skin_tone')">
          <datalist id="skintones">
            <template v-for="st in questionnaire.extraData.skinToneLookups">
              <option :value="st.json.hex" :key="st.json.hex"></option>
            </template>
          </datalist>
        </div>
        <svg-map-basic ref="svgMap" v-if="body_map" :active-frame="activeFrame" :dataitems="dataitems"
          :map-data="body_map" :records="records.bodyparts" @input="(r) => setBodyPartState(r)"
          :class="['psoriasis-body-map m-auto', { 'skintone-dark': brightnessByColor(skintoneHex) < 100 }]"
          :style="{ '--skintoneHex': skintoneHex, '--skintoneDark': brightnessByColor(skintoneHex) < 100 }"
          :zoom-enabled="zoomEnabled">
        </svg-map-basic>
      </card>
    </fieldset>
    <hr>
    <alert type="info" class="m-3">
      <p class="lead">{{ $t('questionnaires.psoriasis.instructions') }}</p>
    </alert>
    <form-group :di="dataitems.psoriasis_redness" :error="errors.psoriasis_redness" v-slot="{ di }" class="my-4 mx-2"
      label-class="fs-5">
      <row class="g-2">
        <column v-for="lui in di.lookup.items" :key="di.name + lui.value" :sizes="{ sm: 6, lg: 4 }"
          :style="$mq | mq({ lg: 'width:20%;' })">
          <btn :id="di.name + '_' + lui.value" outline block v-model="records[di.name]" :true-value="lui.value"
            :false-value="null" :class="['h-100 card-btn p-0 border-5']">
            <row class="g-1">
              <column :sizes="{ sm: 8, lg: 12 }">
                <img v-if="lui.json && lui.json.image" :src="'/assets/images/psoriasis/large/' + lui.json.image"
                  :alt="lui.json.image_alt" class="card-img-top img-responsive"
                  style="object-fit: cover; min-height: 200px;" />
                <div v-else class="card-header" style="min-height: 200px;"></div>
              </column>
              <column :sizes="{ sm: 4, lg: 12 }">
                <div class="card-body p-2">
                  <label :for="di.name + '_' + lui.value">{{ lui.label }}</label>
                  <fa v-if="records[di.name] == lui.value" icon="check" class="mx-2" />
                </div>
              </column>
            </row>
          </btn>
        </column>
      </row>
    </form-group>
    <form-group :di="dataitems.psoriasis_scaling" :error="errors.psoriasis_scaling" v-slot="{ di }" class="my-4 mx-2"
      label-class="fs-5">
      <row class="g-2">
        <column v-for="lui in di.lookup.items" :key="di.name + lui.value" :sizes="{ sm: 6, lg: 4 }"
          :style="$mq | mq({ lg: 'width:20%;' })">
          <btn :id="di.name + '_' + lui.value" outline block v-model="records[di.name]" :true-value="lui.value"
            :false-value="null" :class="['h-100 card-btn p-0 border-5']">
            <row class="g-1">
              <column :sizes="{ sm: 8, lg: 12 }">
                <img v-if="lui.json && lui.json.image" :src="'/assets/images/psoriasis/large/' + lui.json.image"
                  :alt="lui.json.image_alt" class="card-img-top img-responsive"
                  style="object-fit: cover; min-height: 200px;" />
                <div v-else class="card-header" style="min-height: 200px;"></div>
              </column>
              <column :sizes="{ sm: 4, lg: 12 }">
                <div class="card-body p-2">
                  <label :for="di.name + '_' + lui.value">{{ lui.label }}</label>
                  <fa v-if="records[di.name] == lui.value" icon="check" class="mx-2" />
                </div>
              </column>
            </row>
          </btn>
        </column>
      </row>
    </form-group>
    <form-group :di="dataitems.psoriasis_thickness" :error="errors.psoriasis_thickness" v-slot="{ di }"
      class="my-4 mx-2" label-class="fs-5">
      <row class="g-2">
        <column v-for="lui in di.lookup.items" :key="di.name + lui.value" :sizes="{ sm: 6, lg: 4 }"
          :style="$mq | mq({ lg: 'width:20%;' })">
          <btn outline block v-model="records[di.name]" :true-value="lui.value" :false-value="null"
            :class="['h-100 card-btn p-0 border-5']">
            <row class="g-1">
              <column :sizes="{ sm: 8, lg: 12 }">
                <img v-if="lui.json && lui.json.image" :src="'/assets/images/psoriasis/large/' + lui.json.image"
                  :alt="lui.json.image_alt" class="card-img-top img-responsive"
                  style="object-fit: cover; min-height: 200px;" />
                <div v-else class="card-header" style="min-height: 200px;"></div>
              </column>
              <column :sizes="{ sm: 4, lg: 12 }">
                <div class="card-body p-2">
                  <label for="">{{ lui.label }}</label>
                  <fa v-if="records[di.name] == lui.value" icon="check" class="mx-2" />
                </div>
              </column>
            </row>
          </btn>
        </column>
      </row>
    </form-group>
    <fieldset class="my-4 mx-2">
      <legend v-t="'questionnaires.psoriasis.nail'" class=" form-label fs-5"></legend>
      <row class="g-1 card-group">
        <column v-for="diName in ['psoriasis_nail_normal', 'psoriasis_nail_pitting', 'psoriasis_nail_onycholysis']"
          :key="'nail_inv_' + diName" :sizes="{ sm: 6, lg: 4 }" :style="$mq | mq({ lg: 'width:33%;' })" class="">
          <form-group :error="errors[diName]">
            <btn outline block v-model="records[diName]" :true-value="1" :false-value="null"
              :class="['h-100 card-btn p-0 border-5']" :title="dataitems[diName].json.image_alt"
              @click.native="nailClicked(diName)">
              <row class="g-1">
                <column :sizes="{ sm: 8, lg: 12 }">
                  <img v-if="dataitems[diName].json && dataitems[diName].json.image"
                    :src="'/assets/images/psoriasis/large/' + dataitems[diName].json.image"
                    :alt="dataitems[diName].json.image_alt" class="card-img-top img-responsive "
                    style="object-fit: cover; min-height: 140px;" />
                  <div v-else class="card-header" style="min-height: 140px;"></div>
                </column>
                <column :sizes="{ sm: 4, lg: 12 }">
                  <div class="card-body p-2 h-25">
                    <label for="">{{ dataitems[diName].label }}</label>
                    <fa v-if="records[diName]" icon="check" class="mx-2" />
                  </div>
                </column>
              </row>
            </btn>
          </form-group>
        </column>
      </row>
    </fieldset>



    <form-group :cols="[{ lg: 12 }, { sm: 12, md: 6, lg: 12 }]" :error="errors.psoriasis_treatment"
      :di="dataitems.psoriasis_treatment" row-class="align-items-top" class="my-4 mx-2" label-class="fs-5">
      <btn-group outline :disabled="isSubmitted" :di="dataitems.psoriasis_treatment"
        v-model="records.psoriasis_treatment" justified :vertical="['xs', 'sm', 'md'].includes($mq)"></btn-group>
      <form-group v-if="records.psoriasis_treatment == 6" class="mt-2" :error="errors.psoriasis_treatment_other"
        :di="dataitems.psoriasis_treatment_other">
        <textbox v-clear v-model="records.psoriasis_treatment_other">
        </textbox>
      </form-group>
    </form-group>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'psoriasis',
  mixins: [questionnaire],
  computed: {
    ...mapState('resources/patient', ['patient']),
    PsoriasisTypes() {
      var PsoriasisTypes = {}
      if (this.dataitems) {
        Object.keys(this.dataitems).forEach((diKey) => {
          var di = this.dataitems[diKey];
          if (di.json && di.json.group && di.json.group == 'PsoriasisTypes') PsoriasisTypes[di.name] = di
        })
      }
      return PsoriasisTypes

    },
    skinToneNumber() {
      var skinToneNumber = this.questionnaire.extraData.skinToneCode
      if (!skinToneNumber) skinToneNumber = 3
      return skinToneNumber
    },
    skinToneLookupitem() {
      return this.questionnaire.extraData.skinToneLookups.find((lui) => lui.value == this.skinToneNumber)
    },
    yearMask() {
      var yob = this.questionnaire.extraData.personal_details.year_of_birth

      return {
        alias: 'date',
        placeholder: 'yyyy',
        yearrange: { minyear: yob ? yob : 1900, maxyear: (new Date()).getFullYear() }
      }
    },
    body_map() {
      return this.questionnaire.extraData.body_map
    },
    activeFrame() {
      return this.bodyMapGender + this.bodyMapOrientation
    },
    agePsoriasisStarted() {
      if (this.records.psoriasis_start) {
        var yob = this.questionnaire.extraData.personal_details.year_of_birth
        var diff = parseInt(this.records.psoriasis_start - yob)
        return diff
      }
      return null
    }
  },
  data() {
    return {
      bodyMapGender: 'Female',
      bodyMapOrientation: 'Front',
      skintoneHex: null,
      fullscreenActive: false,
      zoomEnabled: false
    }
  },
  mounted() {
    this.bodyMapGender = this.questionnaire.extraData.personal_details.gender == 'M' ? 'Male' : 'Female'
    this.skintoneHex = this.skinToneLookupitem.json.hex
  },
  methods: {
    setBodymapFullscreen() {
      var el = this.$refs.mapAndControls.$el
      if (el) {
        if (!document.fullscreenElement) {
          var promise = el.requestFullscreen();
          this.fullscreenActive = true
        } else {
          document.exitFullscreen();
          this.fullscreenActive = false
        }
      }
    },
    setBodyPartState(r) {
      this.records.bodyparts[r.name] = r.value;
      this.records.bodyparts = Object.fromEntries(Object.entries(this.records.bodyparts).filter(([_, v]) => v != null));
    },
    brightnessByColor(color) {
      var color = "" + color, isHEX = color.indexOf("#") == 0, isRGB = color.indexOf("rgb") == 0;
      if (isHEX) {
        const hasFullSpec = color.length == 7;
        var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
        if (m) var r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
      }
      if (isRGB) {
        var m = color.match(/(\d+){3}/g);
        if (m) var r = m[0], g = m[1], b = m[2];
      }
      if (typeof r != "undefined") return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    },
    nailClicked(diName) {
      if (diName == 'psoriasis_nail_normal') {
        this.records.psoriasis_nail_pitting = null
        this.records.psoriasis_nail_onycholysis = null
      } else if (this.records.psoriasis_nail_normal) {
        this.records.psoriasis_nail_normal = null
      }
    }
  }
}

</script>
<style lang="scss">
.mapAndControls {
  background: var(--bs-gray-500);
}

.mapAndControls:fullscreen {
  background: var(--bs-gray-500);

  svg#Body {
    border: 1px solid black;

    max-height: 94vh;
    margin: auto;
  }
}

.btn.card-btn {
  &.active {
    --bs-btn-border-width: 0.25rem;

  }

  img,
  .card-header {
    aspect-ratio: 5/4;
  }

}

.psoriasis-body-map {
  width: 100%;
  height: 100%;

  svg#Body {
    height: 100%;

  }
}

body.light-theme {
  svg#Body {
    background: #d6dee5
  }
}

body.dark-theme {
  svg#Body {
    background: #181924;
  }

  .skintone-dark svg#Body image {
    mix-blend-mode: overlay;
    filter: brightness(.7) contrast(.8);
  }


}

.skintone-dark svg#Body image {
  mix-blend-mode: overlay;
  filter: brightness(.9) contrast(.9)
}

.skintone-dark svg#Body .bodypart.on {
  fill: url('#myGradient');
  mix-blend-mode: screen;
}

svg#Body {

  .bodyshape {
    fill: var(--skintoneHex);
    stroke: var(--bs-body-bg);
    stroke-width: 5px;
  }

  image {
    mix-blend-mode: multiply;
    // transition: filter 0.6s ease;
    // filter: invert(0) drop-shadow(3px 20px 14px rgba(0, 0, 0, 0.25));
  }

  .bodypart {
    fill: rgba(0, 0, 0, 0);
  }

  .bodypart.on {
    fill: url('#myGradient');
    mix-blend-mode: multiply;
    fill-opacity: 0.7;
    filter: blur(8px);
  }

  .bodypart.hover {
    fill: url('#myGradient');
    fill-opacity: 0.3;
    stroke: rgba(150, 0, 0, 0.3);
    stroke-width: 3px;
  }

  .bodypart.hover:not(.on) {
    fill: url('#myGradient');
  }

  .bodypart:focus {
    /* Provide a fallback style for browsers
     that don't support :focus-visible */
    outline: 1px solid rgba(150, 0, 0, 0.3);
    background: rgba(150, 0, 0, 0.2);
  }

  @supports selector(:focus-visible) {
    .bodypart:focus {
      // Remove the focus indicator on mouse-focus for browsers
      // that do support :focus-visible 
      outline: none;
      background: transparent;
    }
  }

  .bodypart:focus-visible:not(.on) {
    /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
    stroke: rgba(150, 0, 0, 0.3);
    stroke-width: 3px;
    fill: rgba(150, 0, 0, 0.2);
  }

  .bodypart.on:focus-visible {
    /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
    // outline: 4px dashed darkorange;
    // background: transparent;
    stroke: rgba(150, 0, 0, 0.8);
    stroke-width: 3px;
    filter: blur(2px);
  }
}
</style>
