// src/editor.ts
import Vue from "vue";
import store from "~/store";
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import {
	AutoArrangePlugin,
	Presets as ArrangePresets,
	ArrangeAppliers,
} from "rete-auto-arrange-plugin";

import {
	ConnectionPlugin,
	Presets as ConnectionPresets,
} from "rete-connection-plugin";
import {
	VuePlugin,
	Presets as VuePresets,
	VueArea2D,
} from "rete-vue-plugin/vue2";

import { MinimapExtra, MinimapPlugin } from "rete-minimap-plugin";
class Node extends ClassicPreset.Node {
	width = 190;
	height = 130;
}
class Connection<N extends Node> extends ClassicPreset.Connection<N, N> {}

type Schemes = GetSchemes<
	ClassicPreset.Node,
	ClassicPreset.Connection<ClassicPreset.Node, ClassicPreset.Node>
>;
type AreaExtra = VueArea2D<Schemes> | MinimapExtra;

const applier = new ArrangeAppliers.TransitionApplier<Schemes, AreaExtra>({
	duration: 500,
	timingFunction: (t) => t,
	async onTick() {
		// called on every frame update
	},
});

import CustomNode from "./CustomNode.vue";
import CustomFilter from "./CustomFilter.vue";
import CustomConnection from "./CustomConnection.vue";
import CustomSocket from "./CustomSocket.vue";
import { filter } from "vue/types/umd";
import { Obj } from "@popperjs/core";

export async function createEditor(container: HTMLElement) {
	const socket = new ClassicPreset.Socket("socket");

	const editor = new NodeEditor<Schemes>();
	const area = new AreaPlugin<Schemes, AreaExtra>(container);
	const connection = new ConnectionPlugin<Schemes, AreaExtra>();
	const render = new VuePlugin<Schemes, AreaExtra>({
		setup: (context) => {
			const app = new Vue({ ...context, store });

			return app;
		},
	});
	const minimap = new MinimapPlugin<Schemes>({
		boundViewport: true,
	});
	AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
		accumulating: AreaExtensions.accumulateOnCtrl(),
	});

	render.addPreset(
		VuePresets.classic.setup({
			customize: {
				node(context) {
					if (context.payload && context.payload.isModel === true) {
						return CustomNode;
					}
					return CustomFilter;
				},
				socket(context) {
					return CustomSocket;
				},
				connection(context) {
					return CustomConnection;
				},
			},
		})
	);

	render.addPreset(VuePresets.minimap.setup({ size: 200 }));

	connection.addPreset(ConnectionPresets.classic.setup());

	editor.use(area);
	area.use(connection);
	area.use(minimap);
	const arrange = new AutoArrangePlugin<Schemes>();
	arrange.addPreset(ArrangePresets.classic.setup());
	area.use(arrange);

	area.use(render);
	area.addPipe((context) => {
		if (context.type === "nodepicked") {
			const node = editor.getNode(context.data.id);
			if (node) {
				// console.log("nodepicked", node);
			}
		}
		if (context.type === "noderesize") {
		}
		return context;
	});
	AreaExtensions.simpleNodesOrder(area);

	return {
		editor,
		area,
		arrange,
		layout: async (
			animate: boolean,
			options = {
				"elk.algorithm": "mrtree",
				"elk.direction": "RIGHT",
				"elk.spacing.nodeNode": "50",
				"elk.layered.spacing.nodeNodeBetweenLayers": "50",
				"elk.layered.nodePlacement.strategy": "INTERACTIVE",
			}
		) => {
			await arrange.layout({
				applier: animate ? applier : undefined,
				options,
			});
			AreaExtensions.zoomAt(area, editor.getNodes());
		},
		destroy: () => area.destroy(),
	};
}

export async function addNodes(
	editor: NodeEditor<Schemes>,
	area: AreaPlugin<Schemes, AreaExtra>,
	nodesData: Array<any>,
	query: any
) {
	try {
		const socket = new ClassicPreset.Socket("socket");
		let nodes = editor.getNodes();
		console.log("Adding nodes", nodesData, nodes.length);
		for (const nodeData of nodesData) {
			nodes = editor.getNodes();
			console.log("try Adding node", nodeData.model, nodes.length);
			// check if node is already added
			var node = null;
			const nodeExists = nodes.find((node) => node.data.model === nodeData.model);
			if (nodeExists) {
				node = nodeExists;
			} else {
				node = new ClassicPreset.Node(nodeData.model);
				node.isModel = true;
				node.data = nodeData;
			}
			node.data.query = query;
			node.area = area;
			// Add inputs/outputs for fields based on type
			if (
				!Array.isArray(nodeData.primary_key) &&
				nodeData.primary_key &&
				nodeData.primary_key.startsWith(nodeData.table + ".") &&
				!Object.keys(node.outputs).includes(nodeData.primary_key + "_output")
			) {
				node.addOutput(
					nodeData.primary_key + "_output",
					new ClassicPreset.Output(socket)
				);
				node.addInput(
					nodeData.foreign_key + "_input",
					new ClassicPreset.Input(socket)
				);
			}
			if (
				!Array.isArray(nodeData.foreign_key) &&
				nodeData.foreign_key &&
				nodeData.foreign_key.startsWith(nodeData.table + ".") &&
				!Object.keys(node.inputs).includes(nodeData.foreign_key + "_input")
			) {
				node.addInput(
					nodeData.foreign_key + "_input",
					new ClassicPreset.Input(socket)
				);
				node.addOutput(
					nodeData.primary_key + "_output",
					new ClassicPreset.Output(socket)
				);
			}
			nodeData.fields.forEach((field: any, index: number) => {
				if (
					field.lookup &&
					field.lookup.foreign_key &&
					!Object.keys(node.inputs).includes(field.lookup.foreign_key + "_input")
				) {
					node.addInput(
						field.lookup.foreign_key + "_input",
						new ClassicPreset.Input(socket)
					);
				}
				if (
					field.lookup &&
					field.lookup.foreign_key &&
					!Object.keys(node.outputs).includes(field.lookup.foreign_key + "_output")
				) {
					node.addOutput(
						field.lookup.foreign_key + "_output",
						new ClassicPreset.Output(socket)
					);
				}
			});
			if (Array.isArray(nodeData.primary_key)) {
			}
			if (Array.isArray(nodeData.foreign_key)) {
				nodeData.foreign_key.forEach((key: any, index: number) => {
					if (!Object.keys(node.inputs).includes(key + "_input")) {
						node.addInput(key + "_input", new ClassicPreset.Input(socket));
					}
					if (!Object.keys(node.outputs).includes(key + "_output")) {
						node.addOutput(key + "_output", new ClassicPreset.Output(socket));
					}
				});
			}
			nodeData.relationships.forEach((relationship: any, index: number) => {
				if (relationship.inIncludes) {
					if (relationship.type == "HasOneMulti") {
						// foreign key and primary key are arrays
						for (var i = 0; i < relationship.primary_key.length; i++) {
							if (
								relationship.primary_key[i].startsWith(nodeData.table + ".") &&
								!Object.keys(node.outputs).includes(
									relationship.primary_key[i] + "_output"
								)
							) {
								node.addOutput(
									relationship.primary_key[i] + "_output",
									new ClassicPreset.Output(socket)
								);
							}
						}
					} else {
						if (
							relationship.foreign_key.startsWith(nodeData.table + ".") &&
							!Object.keys(node.inputs).includes(relationship.foreign_key + "_input")
						) {
							node.addInput(
								relationship.foreign_key + "_input",
								new ClassicPreset.Input(socket)
							);
						}
						if (
							relationship.primary_key.startsWith(nodeData.table + ".") &&
							!Object.keys(node.inputs).includes(relationship.primary_key + "_input")
						) {
							node.addInput(
								relationship.primary_key + "_input",
								new ClassicPreset.Input(socket)
							);
						}
						if (
							relationship.foreign_key.startsWith(nodeData.table + ".") &&
							!Object.keys(node.outputs).includes(relationship.foreign_key + "_output")
						) {
							node.addOutput(
								relationship.foreign_key + "_output",
								new ClassicPreset.Output(socket)
							);
						}
						if (
							relationship.primary_key.startsWith(nodeData.table + ".") &&
							!Object.keys(node.outputs).includes(relationship.primary_key + "_output")
						) {
							node.addOutput(
								relationship.primary_key + "_output",
								new ClassicPreset.Output(socket)
							);
						}
					}
					//
				}
			});
			// add an input for filter nodes to connect to
			node.addInput(
				nodeData.table + ".*filter_input",
				new ClassicPreset.Input(socket)
			);
			if (nodeExists) {
				console.log("Node already exists", nodeExists);
				continue;
			}
			console.log("await Adding node", nodeData.model);
			await editor.addNode(node);
		}
	} catch (error) {
		console.error(`Error adding node :`, error);
	}
}

export async function addConnections(
	editor: NodeEditor<Schemes>,
	linksData: Array<any>,
	filterObjs: Array<any>,
	queryBuilder: any
) {
	try {
		let nodes = editor.getNodes();
		let connections = editor.getConnections();
		console.log(
			"Adding connections",
			linksData,
			nodes.length,
			connections.length
		);
		if (!nodes || !nodes.length) {
			// console.error("No nodes found");
			return;
		}
		for (var link of linksData) {
			const sourceNode = nodes.find((node) => node.data.model === link.source);
			const targetNode = nodes.find((node) => node.data.model === link.target);
			if (!sourceNode || !targetNode) {
				// console.error("source or target node not found", link);
				continue;
			}
			var sourceKey = link.primary_key + "_output";
			var targetKey = link.foreign_key + "_input";

			if (
				Object.keys(sourceNode.outputs).includes(link.foreign_key + "_output") &&
				Object.keys(targetNode.inputs).includes(link.primary_key + "_input")
			) {
				sourceKey = link.foreign_key + "_output";
				targetKey = link.primary_key + "_input";
			}
			if (
				connections.find(
					(connection) =>
						connection.source === sourceNode.id && connection.target === targetNode.id
				)
			) {
				console.log("connection already exists", sourceNode, targetNode);
				continue;
			}
			const connection = new ClassicPreset.Connection(
				sourceNode,
				sourceKey,
				targetNode,
				targetKey
			);

			await editor.addConnection(connection);
		}
		for (var i = 0; i < filterObjs.length; i++) {
			var filterObj = filterObjs[i];

			var target = filterObj.target;
			var targetNode = nodes.find((node) => {
				return node.data.model === target;
			});
			var sourceNode = nodes.find((node) => {
				return node.label === target + "_filters";
			});
			for (var j = 0; j < filterObj.filters.length; j++) {
				var sourceKey = "filter_" + filterObj.filters[j].key + "_output";
				var targetKey = target + ".*filter_input";
				if (
					Object.keys(sourceNode.outputs).includes(sourceKey) &&
					Object.keys(targetNode.inputs).includes(targetKey)
				) {
					const connection = new ClassicPreset.Connection(
						sourceNode,
						sourceKey,
						targetNode,
						targetKey
					);

					await editor.addConnection(connection);
				}
			}
		}
	} catch (error) {
		console.error("Error adding connections ", error);
	}
}

export async function addFilterNodes(
	editor: NodeEditor<Schemes>,
	area: AreaPlugin<Schemes, AreaExtra>,
	nodesData: Array<any>,
	filterObjs: Array<any>
) {
	try {
		const socket = new ClassicPreset.Socket("socket");
		console.log("Adding filter nodes", filterObjs, editor.getNodes().length);

		for (let i = 0; i < filterObjs.length; i++) {
			const filterObj = filterObjs[i];
			if (!filterObj.target) continue;

			const nodes = editor.getNodes(); // Refetch nodes each iteration
			console.log("Adding filter node", filterObj, nodes.length);

			const nodeExists = nodes.find(
				(node) => node.data.name === filterObj.target + "_filters"
			);

			if (nodeExists) {
				console.log("Filter Node already exists", nodeExists);
				continue;
			}

			const filterNode = new ClassicPreset.Node(filterObj.target + "_filters");
			filterNode.data = filterObj;
			filterNode.width = 180;
			filterNode.height = 100;

			for (let j = 0; j < filterObj.filters.length; j++) {
				const filter = filterObj.filters[j];
				console.log("filterNode addOutput", "filter_" + filter.key + "_output");
				filterNode.addOutput(
					"filter_" + filter.key + "_output",
					new ClassicPreset.Output(socket)
				);
			}

			await editor.addNode(filterNode);
		}
	} catch (error) {
		console.error("Error adding filter node", error);
	}
}
export async function resetView(
	editor: NodeEditor<Schemes>,
	area: AreaPlugin<Schemes, AreaExtra>,
	arrange: AutoArrangePlugin<Schemes>,
	nodesData: Array<any>
) {
	await AreaExtensions.zoomAt(area, editor.getNodes());

	await arrange.layout({
		applier,

		options: {
			"elk.algorithm": "layered",
			"elk.direction": "LEFT",
			"elk.spacing.nodeNode": 50,
			"elk.layered.spacing.nodeNodeBetweenLayers": 50,
		},
	});
}
