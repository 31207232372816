<template>
    <!-- 
        This is the ExportBuilder component. 
        This component is used to build exports. 
        Exports can have multiple sheets, each sheet can have multiple columns.
        if the route name is admin.exports.create then the user is creating a new export.
        if the route name is admin.exports.edit then the user is editing an existing export, and it will be loaded from the store.

      -->
    <div class="exporter m-4">
        <!-- QueryBuilder -->



        <div>
            <row class="mb-2" v-if="dataExport">
                <form-group :sizes="{ lg: 4 }">
                    <label for="exportName">Export Name</label>
                    <input type="text" class="form-control" id="exportName" v-model="dataExport.name" />
                </form-group>
                <form-group :sizes="{ lg: 4 }">
                    <label for="exportDescription">Export Description</label>
                    <input type="text" class="form-control" id="exportDescription" v-model="dataExport.description" />
                </form-group>
            </row>

            <card :body="false" v-if="dataExport">
                <div class="card-header p-0 d-flex align-items-end">
                    <ul class="nav nav-tabs mx-1 pb-0">
                        <li class="nav-item" v-for="(sheet, n) in sheets" :key="'sheet' + n"
                            @dblclick="editSheetName(sheet)">
                            <template v-if="isEditingSheetName && sheet == editingSheetName">
                                <input type="text" class="form-control" v-model="sheet.name"
                                    @keyup.enter="isEditingSheetName = false" />
                            </template>
                            <a v-else @click="setSheet(sheet)" :class="['nav-link', { active: sheet == currentSheet }]">

                                <template>
                                    <span>{{ sheet.name }}</span>
                                </template>
                            </a>

                        </li>
                        <!-- add a sheet nav-link -->
                        <li class="nav-item">
                            <a @click="addSheet" class="nav-link">
                                <fa icon="plus" class="mr-1"></fa>
                                {{ $t('admin.exports.add_sheet') }}
                            </a>
                        </li>
                    </ul>
                    <btn outline @click.native="saveExport" class="ms-auto my-1 me-2">
                        <fa icon="floppy-disk" class="mr-1"></fa>
                        Save Export
                    </btn>
                    <btn outline @click.native="downloadExport({ export_id: dataExport.id })" class="my-1 me-2">
                        <fa icon="play" class="mr-1"></fa>
                        {{ $t('admin.exports.run') }}
                    </btn>
                </div>
                <div class="card-header d-flex align-items-center " v-if="currentSheet">
                    <form-group :sizes="{ lg: 4 }" class="d-flex align-items-center col-auto">
                        <label for="sheetType" class="me-4">Sheet Type</label>
                        <dropdown btn-outline :items="sheetTypes" label-name="name" value-name="name"
                            v-model="currentSheet.type">
                        </dropdown>
                    </form-group>

                    <form-group :sizes="{ lg: 6 }" class="d-flex align-items-center  col-auto"
                        v-if="currentSheet.type == 'dynamic'">
                        <label for="dynamicFor" class="mx-2">For</label>
                        <input class="form-control" type="text" v-model="currentSheet.properties.for">
                        <label for="dynamicIn" class="mx-2">In</label>
                        <input class="form-control" type="text" v-model="currentSheet.properties.in">
                    </form-group>
                    <div class="ms-2" v-if="currentSheet.type == 'dynamic'">
                        {{ currentSheet.properties.for }}: {{ currentSheet.properties[currentSheet.properties.for] }}
                    </div>
                    <div class="ms-auto">
                        <span>
                            Rows:
                        </span>
                        <span class="row-counter" v-if="queryResults">
                            {{ queryResults.total }}
                        </span>
                    </div>
                </div>
                <query-builder v-if="currentSheet" :query-id="currentSheet.query_id" @query-changed="updateQuery"
                    @query-saved="updateQuery" :properties="currentSheet.properties">
                    <template #header>
                        <div v-if="currentSheet" class="flex-grow-1 d-flex col-auto">
                            <dropdown btn-outline :items="queries" label-name="name" value-name="id" @input="setQuery"
                                v-model="currentSheet.query_id">
                            </dropdown>
                            <btn outline @click.native="clearCurrentSheetQuery">
                                {{ $t('admin.exports.clear_query') }}
                                <fa icon="times" class="mr-1"></fa>

                            </btn>
                        </div>
                    </template>
                </query-builder>
                <div class="card-header d-flex" v-if="dataExport">
                    <h5>Columns</h5>
                    <!-- btn to add all columns from -->
                    <btn outline @click.native="addAllColumns" class="ms-auto">
                        <fa icon="plus" class="mr-1"></fa>
                        {{ $t('admin.exports.add_all_columns') }}
                    </btn>
                    <btn outline @click.native="runCurrentQuery" class="ms-2">
                        <fa icon="play" class="mr-1"></fa>
                    </btn>
                    <btn outline @click.native="showColumnsEditor = !showColumnsEditor" class="p-1 ms-auto me-n1 mt-n1">
                        <fa icon="chevron-right" :rotation="showColumnsEditor ? 270 : 90"
                            :style="{ transition: 'all .2s' }" />
                    </btn>
                </div>
                <collapse :show="showColumnsEditor">
                    <row v-if="currentSheet">
                        <column auto>
                            <list-group horizontal class="flex-wrap">
                                <list-group-item v-for="col in currentSheet.columns" :key="col.name">
                                    <div class="d-flex">
                                        <span>{{ col.label }}</span>
                                        <btn outline @click.native="removeColumn(col)" class="ms-4 px-1 py-0">
                                            <fa icon="times" />
                                        </btn>
                                    </div>
                                </list-group-item>
                                <list-group-item v-if="currentSheet.columns && currentSheet.columns.length == 0">
                                    <span>No Columns</span>
                                </list-group-item>
                            </list-group>
                        </column>
                    </row>
                </collapse>
                <data-table v-if="queryResults && currentSheet" :data="queryResults.data"
                    :columns="currentSheet.columns" class="table-responsive" hide-index>
                    <template #header="{ columns }">
                        <div class="header dt-row" v-for="(col, colIndex) in columns">
                            <div class="dt-column dt-column-header">
                                <div class="p-2">
                                    {{ excelColumnLetter(colIndex) }}
                                </div>
                            </div>
                        </div>
                        <div class="header dt-row" v-for="(col, colIndex) in columns">
                            <div class="dt-column dt-column-header">
                                <div class="p-2">
                                    {{ col.label }}
                                </div>
                            </div>
                        </div>
                    </template>
                </data-table>
                <div class="card-footer" v-if="queryResults && currentSheet">
                    <div class="d-flex pt-2">
                        <ul class="pagination m-auto">
                            <li v-for="(link, n) in queryResults.links" :key="'pagination' + n"
                                :class="['page-item', { active: link.active, disabled: !link.url }]">
                                <a @click="updatePagination(link, queryResults)"
                                    :class="['page-link', { 'bg-dark text-white': $root.theme == 'dark' && !link.active }]"
                                    v-html="link.label" />
                            </li>
                        </ul>
                        <div class="m-auto">
                            <span
                                v-t="{ path: 'settings.showing', args: { from: queryResults.from, to: queryResults.to, total: queryResults.total } }"></span>
                        </div>

                    </div>
                </div>
            </card>
        </div>
        <div v-if="$route.name == 'admin.exports.create' && !currentSheet">
            <!-- This is the create route for new exports -->
            <h3>{{ $t('admin.exports.create') }}</h3>
            <p class="lead">
                {{ $t('admin.exports.create_description') }}
            </p>
            <btn @click.native="addSheet">
                <fa icon="plus" class="mr-1"></fa>
                {{ $t('admin.exports.add_sheet') }}
            </btn>
        </div>
    </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
// animate using gsap
import { gsap } from 'gsap'
import { TextPlugin } from 'gsap/TextPlugin';
import QueryBuilder from '~/pages/admin/QueryBuilder.vue'
export default {
    name: "ExportBuilder",
    layout: 'fullscreen',
    components: {
        QueryBuilder
    },
    data() {
        return {
            currentSheet: null,
            isEditingSheetName: false,
            editingSheetName: null,
            showColumnsEditor: false,
            rowCount: 0
        }
    },
    mounted() {
        gsap.registerPlugin(TextPlugin);

        if (this.$route.name == 'admin.exports.edit') {
            // load the export from the store
            this.getExport({ id: this.$route.params.id })
                .then(() => {
                    // load the query builder

                    this.$nextTick(() => {
                        this.currentSheet = this.dataExport && this.dataExport.sheets ? this.dataExport.sheets[0] : null
                        //     this.fetchQueryBuilderData({ params: this.queryParams })
                    })

                })
        }
        this.fetchQueries()

    },
    computed: {
        ...mapState('resources/exports', ['dataExport']),
        ...mapState('resources/QueryBuilder', ['queries', 'queryBuilder', 'queryResults', 'queryBuilderLoading']),
        // ...mapGetters('resources/QueryBuilder', ['flattenedQueryBuilder']),
        sheets() {
            if (this.dataExport && this.dataExport.sheets) {
                var sheets = []
                this.dataExport.sheets.forEach(sheet => {
                    if (sheet.type == 'dynamic') {
                        var props = sheet.properties
                        var iterations = sheet.properties[sheet.properties.for]

                        iterations.forEach((iteration, i) => {
                            var newSheet = JSON.parse(JSON.stringify(sheet))
                            // Replace the placeholder with the iteration value
                            newSheet.name = newSheet.name.replace('${' + sheet.properties.for + '}', iteration)
                            newSheet.properties[sheet.properties.for] = iteration
                            // console.log('newSheet.properties', newSheet.properties, 'iterations', iterations, 'iteration', iteration)
                            sheets.push(newSheet)
                        })
                    }
                    if (sheet.type == 'standard') {
                        sheets.push(sheet)
                    }
                });
                return sheets
            }
            return []
        },
        firstSheet() {
            if (this.dataExport && this.dataExport.sheets)
                return this.dataExport.sheets[0]
            return null
        },
        queryParams() {
            if (!this.currentSheet || this.currentSheet && !this.currentSheet.export_query) return {}
            var query = this.currentSheet.export_query
            var filters = this.cleanFilters()
            // replace any placeholders in the filters with the values from the properties
            // Object.keys(filters).forEach((filterKey, i) => {
            // console.log('filterKey', filterKey, i, filters[filterKey])
            // if (filter.includes('${')) {
            //     var prop = filter.replace('${', '').replace('}', '')
            //     if (this.currentSheet.properties[prop]) {
            //         filters[i] = this.currentSheet.properties[prop]
            //     }
            // }
            // });
            // if (Object.keys(newVal).length > 0) {
            //     for (const key in newVal) {
            //         if (newVal.hasOwnProperty(key) && key.startsWith('filter')) {
            //             const element = newVal[key];
            //             var properties = this.currentSheet.properties
            //             if (properties && properties.for && properties[properties.for]) {
            //                 // element might look like '${issue}' so we need to replace it with the value
            //                 var value = properties[properties.for]
            //                 if (element.includes('${' + properties.for + '}')) {
            //                     if (Array.isArray(element)) {
            //                         element.forEach((el, i) => {
            //                             element[i] = el.replace('${' + properties.for + '}', value)
            //                         });
            //                     }
            //                     // newVal[key] = element.replace('${' + properties.for + '}', value)
            //                     console.log('key', key, 'element', element, newVal[key])
            //                 }
            //             }

            //             // if(element == null || element == ''){
            //             //     delete newVal[key]
            //             // }
            //         }

            //     }
            // }


            return {
                model: query.model,
                ...filters,
                sort: this.cleanSorts(),
                include: query.includes,
                ...this.queryFieldsAsParams
            }
        },
        sheetTypes() {
            return [
                {
                    name: 'standard',
                },
                {
                    name: 'dynamic',
                }
            ]
        }

    },
    methods: {
        ...mapActions('resources/exports', ['getExport', 'downloadExport', 'createExport', 'storeExport', 'updateExport']),
        ...mapActions('resources/QueryBuilder', ['fetchQuery', 'fetchQueryBuilderData', 'runQuery', 'fetchQueries', 'clearQuery']),
        updatePagination(link, pagination, method = false) {
            var url = new URL(link.url, pagination.path)
            var search = url.search.substring(1);
            var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            // this.$router.replace({ name: this.$route.name, query: { page: params.page } })
            // console.log('updatePagination', params)
            this.runQuery({
                params: {
                    ...this.queryParams,
                    page: params.page
                }
            });
        },
        editSheetName(sheet) {
            this.isEditingSheetName = true
            this.editingSheetName = sheet
        },
        saveExport() {
            if (this.dataExport.class_name == null) {
                this.dataExport.class_name = 'App\\Exports\\CustomExport'
            }
            if (this.dataExport.id)
                this.updateExport(this.dataExport)
            else
                this.storeExport(this.dataExport)
                    .then(() => {
                        this.$router.push({ name: 'admin.exports.edit', params: { id: this.dataExport.id } })
                    })

        },
        excelColumnLetter(colIndex) {
            // should return a to z then aa to az then ba to bz etc
            var dividend = colIndex + 1;
            var columnName = '';
            var modulo;
            while (dividend > 0) {
                modulo = (dividend - 1) % 26;
                columnName = String.fromCharCode(65 + modulo) + columnName;
                dividend = parseInt((dividend - modulo) / 26);
            }
            return columnName;
        },
        cleanFilters() {
            if (!this.currentSheet) return {}
            const query = { ...this.currentSheet.export_query };
            var filters = {};
            // filters should return an object with the name as the key and the value as the value
            Object.keys(query.filters).forEach((filterKey) => {
                var filter = { ...query.filters[filterKey] };
                var name = 'filter[' + filterKey + ']';
                if (Object.keys(filter).length > 0 && !Object.keys(filter).includes('parameters')) {
                    // these are deeper filters keyed by operator - should pass to the server as filter[filterKey][operator]
                    Object.keys(filter).forEach((operator) => {
                        filters[name + '[' + operator + ']'] = filter[operator].parameters.length > 1 ? filter[operator].parameters.join(',') : filter[operator].parameters.length > 0 ? filter[operator].parameters[0] : true;
                    });
                } else {
                    // only include if all parameters are not empty
                    var paramsAreValid = filter.parameters && filter.parameters.length > 0 && filter.parameters.every(param => param != '' && param != null);

                    if (paramsAreValid) {
                        filter.parameters = [...filter.parameters]
                        filter.parameters.forEach((param, i) => {
                            var paramIsString = typeof param == 'string'
                            if (Object.keys(this.currentSheet.properties).length > 0 && paramIsString && param.includes('${')) {
                                var prop = param.replace('${', '').replace('}', '')
                                if (this.currentSheet.properties[prop]) {
                                    filter.parameters[i] = this.currentSheet.properties[prop]
                                }
                            }
                        });
                        filters[name] = filter.parameters && filter.parameters.length ? filter.parameters.join(',') : true;
                    }
                }

            });
            return { ...filters };
        },
        cleanSorts() {
            if (!this.currentSheet) return {}
            const query = { ...this.currentSheet.export_query };
            var sorts = query.sorts.join(',')
            return sorts
        },
        runCurrentQuery() {
            this.runQuery({
                params: this.queryParams
            });
        },
        setSheet(sheet) {
            this.currentSheet = sheet

            if (this.currentSheet.export_query) {
                console.log('setSheet', sheet, this.currentSheet.export_query)

                Promise.all([this.fetchQuery({ params: { id: this.currentSheet.export_query.id } }), this.fetchQueryBuilderData({ params: this.queryParams })])
            }
        },
        addSheet() {
            if (!this.dataExport) {
                this.createExport({
                    name: 'New Export',
                    description: 'New Export Description',
                    sheets: [{
                        name: 'Sheet 1',
                        type: 'standard',
                        query_id: null,
                        properties: {},
                        export_query: null
                    }]
                })
                this.currentSheet = this.dataExport.sheets[0]
            } else {
                this.dataExport.sheets.push({
                    name: 'Sheet ' + (this.dataExport.sheets.length + 1),
                    type: 'standard',
                    query_id: null,
                    properties: [],
                    export_query: {
                        model: null,
                        filters: {},
                        sorts: [],
                        includes: []
                    }
                })
                this.currentSheet = this.dataExport.sheets[this.dataExport.sheets.length - 1]
            }

        },
        addColumns(columns) {
            if (!this.currentSheet.columns) this.currentSheet.columns = []
            this.currentSheet.columns = this.currentSheet.columns.concat(columns)
        },
        addAllColumns() {
            // iterate through flattened query builder and add all fields
            this.flattenedQueryBuilder.forEach(element => {
                if (element.fields) {
                    element.fields.forEach(field => {
                        // console.log(field)
                        var name = element.key ? element.key + '.' + field.name : field.name
                        if (this.currentSheet.columns.find(col => col.name == name)) return
                        this.addColumns([{
                            name: name,
                            label: name,
                            type: field.type
                        }])
                    });
                }
            });
        },
        removeColumn(column) {
            this.currentSheet.columns = this.currentSheet.columns.filter(col => col.name != column.name)
        },
        setQuery(query_id) {
            this.currentSheet.query_id = query_id
            this.$set(this.currentSheet, 'query_id', query_id)
            // this.fetchQueryBuilderData({ params: this.queryParams })
        },
        clearCurrentSheetQuery() {
            this.clearQuery()
        },
        updateQuery(query) {
            if (!query.id) {
                this.currentSheet.export_query = null
                this.currentSheet.query_id = null
            } else {
                this.currentSheet.export_query = query
                this.currentSheet.query_id = query.id
            }
        },
    },

    watch: {
        dataExport: {
            handler: function (newVal, oldVal) {
                // console.log('dataExport watch', newVal, oldVal)
                // if (this.currentSheet && this.currentSheet.export_query && this.queryParams.model)
                //     this.fetchQueryBuilderData({ params: this.queryParams })
            },
            deep: true
        },
        queryParams: {
            handler: function (newVal, oldVal) {
                if (this.currentSheet && this.currentSheet.export_query && this.queryParams.model) {
                    // if (Object.keys(newVal).length > 0) {
                    //     for (const key in newVal) {
                    //         if (newVal.hasOwnProperty(key) && key.startsWith('filter')) {
                    //             const element = newVal[key];
                    //             var properties = this.currentSheet.properties
                    //             if (properties && properties.for && properties[properties.for]) {
                    //                 // element might look like '${issue}' so we need to replace it with the value
                    //                 var value = properties[properties.for]
                    //                 if (element.includes('${' + properties.for + '}')) {
                    //                     if (Array.isArray(element)) {
                    //                         element.forEach((el, i) => {
                    //                             element[i] = el.replace('${' + properties.for + '}', value)
                    //                         });
                    //                     }
                    //                     // newVal[key] = element.replace('${' + properties.for + '}', value)
                    //                     console.log('key', key, 'element', element, newVal[key])
                    //                 }
                    //             }

                    //             // if(element == null || element == ''){
                    //             //     delete newVal[key]
                    //             // }
                    //         }

                    //     }
                    // }
                    // this.fetchQueryBuilderData({ params: newVal })
                    this.runCurrentQuery()
                }
            },
            deep: true
        },
        'currentSheet.export_query.filters': {
            handler: function (newVal, oldVal) {
                // this.runCurrentQuery()
                // console.log('query.filters watch', this.queryParams)
                // if (!this.queryBuilderLoading) this.fetchQueryBuilderData({ params: this.queryParams })
            },
            deep: true
        },
        'queryResults.total': {
            handler: function (newVal, oldVal) {
                if (oldVal == undefined) return
                // transition from text of oldVal to text of newVal
                var el = document.querySelector('.row-counter')
                let tween = gsap.fromTo(el,
                    {
                        textContent: oldVal
                    },
                    {
                        textContent: newVal,

                        duration: 0.5,
                        ease: "power2.inOut",
                        snap: {
                            textContent: 1
                        },
                        onUpdate: function () {
                            this.rowCount = Math.ceil(this.targets()[0].textContent);
                        },
                        onComplete: function () {
                            this.rowCount = Math.ceil(this.targets()[0].textContent);
                        }
                    })

            }
        }
    }
}
</script>

<style lang="scss" scoped></style>