export default {
    computed: {
        queryBuilder() {
            return this.$store.state['resources/QueryBuilder'].queryBuilder
        },
        keyList() {
            var qb = this.$store.state['resources/QueryBuilder'].queryBuilder
            // console.log('Key list', this.queryBuilder, qb);
            return this.mapModelToKeyList(qb)
                .sort((a, b) => a.key.localeCompare(b.key))
                .filter((node, index, self) =>
                    index === self.findIndex((t) => (
                        t.key === node.key
                    ))
                );
        },
        filteredKeyList() {
            return this.keyList.filter(node => {
                if (this.filterKey.includes('.')) {
                    var keyArr = this.filterKey.split('.');
                    return node.key.includes(this.filterKey);
                } else {
                    return node.key.includes(this.filterKey) && !node.key.includes('.');
                }
                // if (node.key.includes(this.filterKey)) console.log('Filtering key list', node.key, this.filterKey);
                return node.key.includes(this.filterKey);
            })
                // sort by isField, isScope, isRelationship
                .sort((a, b) => {
                    if (a.isField && !b.isField) return -1;
                    if (b.isField && !a.isField) return 1;
                    if (a.isScope && !b.isScope) return -1;
                    if (b.isScope && !a.isScope) return 1;
                    if (a.isRelationship && !b.isRelationship) return -1;
                    if (b.isRelationship && !a.isRelationship) return 1;
                    return 0;
                });
        },
        filterOperators() {
            return [
                { label: 'Partial', value: 'partial', params: 1 },
                { label: 'Exact', value: 'eq', params: 1 },
                { label: 'Not Equal', value: 'ne', params: 1 },
                { label: 'Greater Than', value: 'gt', params: 1 },
                { label: 'Greater Than or Equal', value: 'gte', params: 1 },
                { label: 'Less Than', value: 'lt', params: 1 },
                { label: 'Less Than or Equal', value: 'lte', params: 1 },
                { label: 'In', value: 'in', params: 1 },
                { label: 'Not In', value: 'nin', params: 1 },
                { label: 'Between', value: 'between', params: 2 },
                { label: 'Not Between', value: 'notbetween', params: 2 },
                { label: 'Is Null', value: 'isnull', params: 0 },
                { label: 'Is Not Null', value: 'isnotnull', params: 0 }

            ]

        },
    },
    methods: {
        mapModelToKeyList(model, parentKey = '') {
            // console.log('Mapping model to key list', model, parentKey);
            // this function can be called recursively to map the model to a keyList
            var fields = model.fields;
            var scopes = model.scopes;
            var relationships = model.relationships;
            var keyList = [];
            if (fields) {
                fields.forEach(field => {
                    var key = field.name;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: field, isField: true });
                });
            }
            if (scopes) {
                Object.keys(scopes).forEach(scope => {
                    var key = scope;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: scopes[scope], isScope: true });
                });
            }
            if (relationships) {
                relationships.forEach(relationship => {

                    var key = relationship.name;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: relationship, isRelationship: true });
                    keyList = keyList.concat(this.mapModelToKeyList(relationship, key));
                });
            }
            return keyList;

        },
        findFilterFromKeyList(key) {
            if (!this.keyList || this.keyList.length == 0) return null;
            return this.keyList.find(node => node.key === key);
        },

        changeFilterOperator(operator, filterName, index) {
            var was = Object.keys(this.filters[filterName])[index];
            var filter = this.filters[filterName];
            var fkl = this.findFilterFromKeyList(filterName);
            if (fkl && fkl.isField && fkl.value.type) {
                var paramCount = this.filterOperators.find(op => op.value === operator).params;
                var parameters = filter[was].parameters.length == paramCount ? filter[was].parameters : Array(paramCount).fill('');
                filter[operator] = { parameters };

            }
            delete filter[was];
            var filters = { ...this.filters, [mapModelToKeyList]: filter }
            this.$emit('update:filters', filters);
        },
        suitableComponent(filterModel, param = null, index = -1, operator = null, operatorIndex = -1) {
            if (!filterModel) return {};
            // console.log('Suitable component', filterModel, param)
            var cd = this.componentDefinitions;
            // we are going to combine the suitable input, suitable input binding and suitable events into a single object
            // this object will contain the name of the component, the bindings and the events
            // the bindings will be the v-bind for the component
            // the events will be the v-on for the component
            // the name will be the name of the component
            var el = {}

            if (index > -1) {
                if (filterModel.isScope) {
                    // el.bindings.value = param;
                    if (filterModel.value.parameters) {
                        var scopeParam = filterModel.value.parameters[index];
                        el = { ...this.componentFromScopeParam(scopeParam) };
                        if (this.isGlobalLookup(scopeParam.name)) {
                            el = { ...cd.dropdown };
                            const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === scopeParam.name);
                            el.bindings = { ...el.bindings, items: lookup.items, value: param, multiple: true };
                        }
                    } else {
                        el = cd.checkbox;
                    }
                }
                if (filterModel.isField) {
                    if (filterModel.value.lookup) {
                        el = cd.dropdown;
                        const field = filterModel.value;

                        const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === filterModel.value.lookup.name);
                        // console.log('Lookup', lookup, filterModel.value.lookup.name, param);
                        el.bindings = { ...el.bindings, items: lookup.items, value: param };
                        if (operator && operatorIndex > -1) {
                            if (operator === 'in' || operator === 'nin') {
                                el.bindings.multiple = true;
                            }
                        }

                    }
                    else if (this.isGlobalLookup(filterModel.name)) {
                        el = cd.dropdown;
                        const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === filterModel.name);
                        el.bindings.items = { ...lookup.items }
                        if (Array.isArray(param)) {
                            el.bindings.value = param;
                            el.bindings.multiple = true;
                        } else {
                            el.bindings.value = [param];
                        }

                    }
                    else if (param && typeof param == 'string' && param.startsWith('${') && param.endsWith('}')) {
                        el = cd.textbox;
                        el.bindings.value = param;
                    }
                    else if (filterModel.value.type) {
                        switch (filterModel.value.type) {
                            case 'integer':
                                el = cd.number;
                                break;
                            case 'string':
                                el = cd.textbox;
                                break;
                            case 'boolean':
                                el = cd.checkbox;
                                break;
                            case 'date':
                                el = cd.datepicker;
                                break;
                            case 'datetime':
                                el = cd.datepicker;
                                break;
                            case 'time':
                                el = cd.datepicker;
                                break;
                            default:
                                el = cd.textbox;

                        }
                        el.bindings.value = param

                    }
                }
                if (this.isCustomFilter(filterModel.key)) {
                    el.name = filterModel.key;

                    el.bindings.value = typeof param == 'string' || typeof param == 'number' ? [param] : param;
                }
                el.events = {
                    change: (v) => this.updateValue(v, filterModel, index, operator, operatorIndex),
                    input: (v) => this.updateValue(v, filterModel, index, operator, operatorIndex),
                }
            }
            // console.log('Suitable component', filterModel, el, el.bindings);
            return el

        },
        isCustomFilter(filterName) {
            // Define your logic to determine if a filter is custom
            const customFilters = ['country_id', 'country'];
            return customFilters.includes(filterName);
        },
    }
}
