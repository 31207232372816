<template>
    <div class="node card" :class="{ selected: data.selected }" :style="nodeStyles" data-testid="node">
        <div class="card-header d-flex p-0">
            <scope v-if="fieldHasInput({ name: '*filter' })" :input="fieldInput({ name: '*filter' })"
                v-slot="{ input }">
                <Ref class="input-socket" :emit="emit" :data="{
                    type: 'socket',
                    side: 'input',
                    key: data.data.table + '.*filter_input',
                    nodeId: data.id,
                    payload: input.socket,
                }" data-testid="input-socket" />
            </scope>
            <div class="flex-grow-1 p-2 card-title" data-testid="title">{{ data.label }}</div>
        </div>

        <!-- Instead we are going to have rows for each field, each one could begin with an input and finish with an output -->
        <list-group flush v-if="data.data && data.data.fields">
            <list-group-item v-for="field in data.data.fields" :key="field.name" class="p-0 d-flex">
                <scope v-if="fieldHasInput(field)" :input="fieldInput(field)" v-slot="{ input }">
                    <Ref class="input-socket" :emit="emit" :data="{
                        type: 'socket',
                        side: 'input',
                        key: data.data.table + '.' + field.name + '_input',
                        nodeId: data.id,
                        payload: input.socket,
                    }" data-testid="input-socket" />
                </scope>
                <div class="flex-grow-1 p-2">{{ field.name }}: </div>
                <div class="p-2">
                    {{ field.type }}
                </div>
                <scope v-if="fieldHasOutput(field)" :output="fieldOutput(field)" v-slot="{ output }">
                    <Ref class="output-socket" :emit="emit" :data="{
                        type: 'socket',
                        side: 'output',
                        key: data.data.table + '.' + field.name + '_output',
                        nodeId: data.id,
                        payload: output.socket,
                    }" data-testid="output-socket" />
                </scope>
            </list-group-item>
        </list-group>

        <!-- Outputs-->
        <!-- <div class="output" v-for="[key, output] in outputs()" :key="key + seed" :data-testid="'output-' + key">
            <div class="output-title" data-testid="output-title">
                {{ output.name }}
            </div>
            <Ref class="output-socket" :emit="emit" :data="{
                type: 'socket',
                side: 'output',
                key: key,
                nodeId: data.id,
                payload: output.socket,
            }" data-testid="output-socket" />
        </div> -->
        <!-- Controls-->
        <Ref class="control" v-for="[key, control] in controls()" :key="key + seed" :emit="emit"
            :data="{ type: 'control', payload: control }" :data-testid="'control-' + key" />
        <!-- Inputs-->
        <!-- <div class="input" v-for="[key, input] in inputs()" :key="key + seed" :data-testid="'input-' + key">
            <Ref class="input-socket" :emit="emit" :data="{
                type: 'socket',
                side: 'input',
                key: key,
                nodeId: data.id,
                payload: input.socket,
            }" data-testid="input-socket" />
            <div class="input-title" v-show="!input.control || !input.showControl" data-testid="input-title">
                {{ input.label }}
            </div>
            <Ref class="input-control" v-show="input.control && input.showControl" :emit="emit"
                :data="{ type: 'control', payload: input.control }" data-testid="input-control" />
        </div> -->
        <div class="card-footer">
            <dropdown v-if="data.selected" btn-outline :items="data.data.relationships" label-name="name"
                value-name="name" multiple>
                <template v-slot:btn="{ toggleDropdown }">
                    <div class="btn" @click.stop="toggleDropdown" @pointerdown.stop="">
                        <fa icon="diagram-project" fixed-width class="me-2" />

                    </div>
                </template>
                <template v-slot:list="{ item }">
                    <button :id="'dd_' + item.name" :key="item.name" :class="['dropdown-item d-flex me-4', { 'active': data.data.query.includes?.includes(getInclude(item, data.data)) }

                    ]" @pointerdown.stop="" @click="toggleInclude(item, data.data)" :value="item">
                        <span>{{ item.name }}</span>
                        <small class="ms-auto">{{ item.type }}</small>
                    </button>
                </template>
            </dropdown>
        </div>
    </div>
</template>

<script lang="js">
import { ref } from 'vue'
import { Ref } from 'rete-vue-plugin/vue2'
function sortByIndex(entries) {
    entries.sort((a, b) => {
        const ai = a[1] && a[1].index || 0
        const bi = b[1] && b[1].index || 0

        return ai - bi
    })
    return entries
}

export default {
    props: ['data', 'emit', 'seed'],

    components: {
        Ref
    },
    mounted() {
        this.$nextTick(() => {
            this.setDimensions()
        })
    },
    updated() {
        this.$nextTick(() => {
            // console.log('updated')
            this.setDimensions()
        })
    },
    computed: {
        nodeStyles() {
            return {
                width: Number.isFinite(this.data.width) ? `${this.data.width}px` : '',
                height: Number.isFinite(this.data.height) ? `${this.data.height}px` : ''
            }
        },
    },
    methods: {
        async em() {
            await this.emit({ 'selected': this.data.id })
        },
        setDimensions() {
            this.$nextTick(() => {
                var bb = this.$el.getBoundingClientRect()
                this.data.width = bb.width
                this.data.height = bb.height
                this.emit({ type: 'noderesize', data: bb })
            });
        },

        toggleInclude(include, relationship = null) {
            var query = this.data.data.query
            console.log('query', query.description)
            var includeName = include.name;
            if (relationship) {
                var isRoot = relationship && relationship.model == query.model;
                includeName = isRoot ? include.name : relationship.table + '.' + include.name;
            }
            if (query.includes.includes(includeName)) {
                query.includes.splice(query.includes.indexOf(includeName), 1);
            } else {
                query.includes.push(includeName);
                // if (!this.query.fields.includes(include.primary_key)) {
                //     this.query.fields.push(include.primary_key);
                //     this.query.fields.push(include.foreign_key);

                // }
            }
            this.data.data.query = query;
            // this.runCurrentQuery();
        },
        getInclude(relationship, model) {
            return model.name ? model.name + '.' + relationship.name : relationship.name;
        },
        inputs() {
            return sortByIndex(Object.entries(this.data.inputs))
        },
        controls() {
            return sortByIndex(Object.entries(this.data.controls))
        },
        outputs() {
            return sortByIndex(Object.entries(this.data.outputs))
        },
        fieldHasInput(field) {
            if (this.data.inputs) {
                var inputName = this.data.data.table + '.' + field.name + '_input'
                return this.data.inputs[inputName] !== undefined
            }
        },
        fieldInput(field) {
            if (this.data.inputs) {
                var inputName = this.data.data.table + '.' + field.name + '_input'
                return this.data.inputs[inputName]
            }
        },
        fieldHasOutput(field) {
            if (this.data.outputs) {
                var outputName = this.data.data.table + '.' + field.name + '_output'
                // console.log(outputName, this.data.outputs, this.data.outputs[outputName], this.data.inputs)
                return this.data.outputs[outputName] !== undefined
            }
            // var outputName = this.data.data.table + '.' + field.name
            // return this.data.outputs[outputName] !== undefined
        },
        fieldOutput(field) {
            if (this.data.outputs) {
                var outputName = this.data.data.table + '.' + field.name + '_output'
                return this.data.outputs[outputName]
            }
            // var outputName = this.data.data.table + '.' + field.name
            // return this.data.outputs[outputName]
        }
    },
}
</script>

<style lang="scss" scoped>
@use "sass:math";

@import "./vars";

.node {
    user-select: none;

    &.selected {
        --bs-card-border-color: var(--bs-info);
    }

    min-width: $node-min-width;
    // background: black;
    // border: 2px solid grey;
    // border-radius: 10px;
    // cursor: pointer;
    // box-sizing: border-box;
    // width: $node-width;
    // height: auto;
    // padding-bottom: 6px;
    position: relative;
    // user-select: none;

    // &:hover {
    //     background: #333;
    // }

    // &.selected {
    //     border-color: red;
    // }

    .title {
        color: white;
        font-family: sans-serif;
        font-size: 18px;
        padding: 8px;
    }

    .output {
        text-align: right;
    }

    .input {
        text-align: left;
    }

    .output-socket {
        text-align: right;
        margin-right: -1px;
        display: inline-block;
    }

    .input-socket {
        text-align: left;
        margin-left: -1px;
        display: inline-block;
    }

    .input-title,
    .output-title {
        vertical-align: middle;
        color: white;
        display: inline-block;
        font-family: sans-serif;
        font-size: 14px;
    }

    .input-control {
        z-index: 1;
        vertical-align: middle;
        display: inline-block;
    }

    .control {}
}
</style>