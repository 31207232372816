import store from '~/store'
import Validator from 'validatorjs'
import en from '~/lang/en/validation.json';
import cs from '~/lang/cs/validation.json';
import de from '~/lang/de/validation.json';
import el from '~/lang/el/validation.json';
import pt from '~/lang/pt/validation.json';
import fr from '~/lang/fr/validation.json';
import nl from '~/lang/nl/validation.json';
import nb from '~/lang/nb/validation.json';
import ro from '~/lang/ro/validation.json';
import pl from '~/lang/pl/validation.json';
import sv from '~/lang/sv/validation.json';


import moment from 'moment';

Validator.setMessages('en', en);
Validator.setMessages('cs', cs);
Validator.setMessages('de', de);
Validator.setMessages('el', el);
Validator.setMessages('pt', pt);
Validator.setMessages('fr', fr);
Validator.setMessages('nl', nl);
Validator.setMessages('nb', nb);
Validator.setMessages('ro', ro);
Validator.setMessages('pl', pl);
Validator.setMessages('sv', sv);



window.Validator = Validator

  ; (async function () {
    var locale = store.getters['lang/locale']
    Validator.useLang(locale)
  })()

Validator.registerImplicit('generic', function (val, requirement, attribute) {
  var obj = this.validator.input;
  var parentPath = attribute.split('.');
  if (parentPath.length > 1) {
    parentPath.pop();
    parentPath = parentPath.join('.')
    obj = this.validator._objectPath(this.validator.input, parentPath)
  }
  var result = calculateBrackets(requirement, obj);
  return result
}, 'The :attribute is required')
Validator.register('date', function (date, requirement, attribute) {
  if (moment(date, 'YYYY-MM-DD').isValid()) return true;
}, 'The :attribute is not a valid date')
Validator.register('fuzzy_day', function (date, requirement, attribute) {
  if (unknownDayDateString(date)) {
    date = replaceChar(date, 8, 0);
    date = replaceChar(date, 9, 1);
  }
  return moment(date, 'YYYY-MM-DD', true).isValid();
}, 'The :attribute is not a valid date')
Validator.register('fuzzy_date', function (date, requirement, attribute) {
  if (unknownDayDateString(date)) {
    date = replaceChar(date, 8, 0);
    date = replaceChar(date, 9, 1);
  }
  if (unknownMonthDateString(date)) {
    date = replaceChar(date, 5, 0);
    date = replaceChar(date, 6, 1);
  }
  return moment(date, 'YYYY-MM-DD', true).isValid();
}, 'The :attribute is not a valid date')

Validator.register('fuzzy_month', function (date, requirement, attribute) {
  if (unknownDayDateString(date)) {
    date = replaceChar(date, 8, 0);
    date = replaceChar(date, 9, 1);
  }
  if (unknownMonthDateString(date)) {
    date = replaceChar(date, 5, 0);
    date = replaceChar(date, 6, 1);
  }
  return moment(date, 'YYYY-MM-DD', true).isValid();
}, 'The :attribute is not a valid date')

Validator.register('beforeNow', function (date, requirement, attribute) {
  var mom = moment(date, 'YYYY-MM-DD')
  if (mom.isValid() && mom > moment()) {
    return false
  }
  return true;
}, 'That date is in the future')
Validator.register('under100', function (date, requirement, attribute) {
  // console.log('under100 rule', date, requirement, attribute)
  var age = moment().diff(moment(date), "years")
  if (age != age) return true;
  if (age < 100) {
    return true;
  }

}, 'That date is in the future')
Validator.register('above18', function (date, requirement, attribute) {
  // console.log('under100 rule', date, requirement, attribute)
  var age = moment().diff(moment(date), "years")
  if (age >= 18) {
    return true;
  }

}, 'Must be an adult')
Validator.register('unique', function (date, requirement, attribute) {
  return true;

}, 'Email already registered')
Validator.register('afterBirth', function (date, requirement, attribute) {
  if (moment(date, 'YYYY-MM-DD') > moment(patient.demographics.DoB)) return true;
}, 'That date is before the patient was born')
Validator.registerImplicit('required_if', function (val, requirement, attribute) {

  var other = requirement.split(',');
  var records = this.validator.input;
  var required = false;
  // if other is set
  var parentPath = attribute.split('.');
  if (parentPath.length > 1) {
    parentPath.pop();
    parentPath = parentPath.join('.')
    records = this.validator._objectPath(this.validator.input, parentPath)
  }

  if (other.length)
    if (records[other[0]] == other[1]) required = true;
  if (required) {
    if (val !== null && val !== "") return true;
    return false;
  }
  return true
}, 'required')

Validator.registerImplicit('required_with', function (val, requirement, attribute) {
  var others = requirement.split(',');
  var records = this.validator.input;
  var required = false;
  // if other is set
  if (val) {
    return true
  }
  for (var i = 0; i < others.length; i++) {
    if (records[others[i]]) required = true;
  }
  return !(required && (val == null || val == false))
}, 'required', true)
Validator.register('size', function (val, requirement, attribute) {
  return val.length == requirement;
}, 'required', true)
Validator.registerImplicit('required_without', function (val, requirement, attribute) {

  if (!this.validator.input[requirement] && val == null) {
    return false;
  } else {
    return true;
  }
}, 'required', true)
Validator.registerImplicit('required_without_all', function (val, requirement, attribute) {
  var others = requirement.split(',');
  var records = this.validator.input;
  var required = true;
  // if other is set
  if (val) {
    return true;
  }
  for (var i = 0; i < others.length; i++) {
    if (records[others[i]]) required = false;
  }
  if (required)
    if (val == null) {
      return false;
    }
  return true;
}, 'required', true)

Validator.registerImplicit('required_if_any_one', function (val, requirement, attribute) {
  var others = requirement.split(',');
  var records = this.validator.input;
  var required = false;
  // if other is set
  if (val) return true;
  $.each(others, function (i, other) {
    if (records[other]) required = true;
  });
  if (required)
    if (val === undefined || val === null) return false;
  return true;
}, 'Invalid', true)

Validator.registerImplicit('requiredIfOlder', function (val, requirement, attribute) {
  var records = this.validator.input;
  var required = false;
  // if other is set
  if (val) return true;
  var age = this.validator.vm.age;
  // console.log(age > requirement)
  return age <= requirement;
}, 'Invalid', true)

Validator.register('digits_between', function (val, requirement, attribute) {
  var val = parseInt(val);
  var minmax = requirement.split(','),
    min = parseInt(minmax[0]),
    max = parseInt(minmax[1]);
  if (val >= min && val <= max) {
    return true;
  }
}, 'Invalid', true);
export function replaceChar(string, index, value) {
  var arr = string.split('');
  arr[index] = value;
  string = arr.join('');
  return string
}

export function calculateBrackets(command, record, functionParams) {
  if (functionParams == undefined) functionParams = {};
  var count = 0;
  var brackets = [];
  var depth = 0;
  var inFunction = false;
  var functionStart = 0;
  var lastPossibleFunctionNameStart = -1;
  var functionNameStart = 0;
  var functionParameterCounter = 0;
  var isLetter = /[a-zA-Z]/;
  for (var i = 0; i < command.length; i++) {
    switch (command[i]) {
      case ".":
        {
          lastPossibleFunctionNameStart = i;
        }
        break;
      case "(":
        {
          if (depth == 0) {
            if (i != 0 && command[i - 1].match(isLetter)) {
              inFunction = true
            }
            if (!inFunction) {
              if (!brackets[count]) brackets[count] = [];
              brackets[count][0] = i;
            } else {
              for (var j = i - 2; ; j--) {
                if (j < 0) {
                  functionNameStart = -1;
                  break;
                }
                if (!command[j].match(isLetter)) {
                  functionNameStart = j;
                  break;
                }
              }
              // functionNameStart = lastPossibleFunctionNameStart
              functionStart = i
            }
          }
          depth++;
        }
        break;
      case ")":
        {
          if (inFunction) {
            inFunction = false
            var parameter = command.substring(functionStart + 1, i);
            var functionName = command.substring(functionNameStart + 1, functionStart);
            var startOfReplace = functionNameStart;
            var lengthOfReplaced = i - functionNameStart + 1;
            var replaceText = "??function??." + functionParameterCounter;
            command = substr_replace(command, replaceText, startOfReplace + 1, i + 1);
            i = i - Math.abs(replaceText.length - lengthOfReplaced);
            functionParams[functionParameterCounter++] = { "parameter": parameter, "name": functionName };
          } else if (depth == 1) {
            brackets[count][1] = i;
            count++;
          }
          depth--;
        }
        break;
    }
  }
  brackets = brackets.reverse();
  for (var i = 0; i < brackets.length; i++) {
    var from = brackets[i][0] + 1;
    var till = brackets[i][1];
    var subCommand = command.substring(from, till)
    var bracketResult = calculateBrackets(subCommand, record, functionParams);
    if (bracketResult == false) bracketResult = "false";
    else if (bracketResult == true) bracketResult = "true";
    command = substr_replace(command, bracketResult, brackets[i][0], brackets[i][1] + 1);
  }
  return calculateOr(command, record, functionParams);
}
export function substr_replace(string, replaceWith, start, end) {
  return string.substring(0, start) + replaceWith + string.substring(end, string.length)
}
export function calculateOr(command, record, functionParams) {
  var parts = command.split("||");
  var combine = null;
  for (var i = 0; i < parts.length; i++) {
    var part = parts[i];
    // echo (int)static::calculateAnd($part, record) . command."<br/>";
    if (combine === null) {
      combine = calculateAnd(part, record, functionParams);
    } else {
      combine |= calculateAnd(part, record, functionParams);
    }

    if (combine == true) return true;
  }
  return combine;
}
export function calculateAnd(command, record, functionParams) {
  var parts = command.split("&&");
  var combine = null;
  for (var i = 0; i < parts.length; i++) {
    var part = parts[i];
    if (combine === null) {
      combine = calculateComparators(part, record, functionParams);
    } else {
      combine &= calculateComparators(part, record, functionParams);
    }

    if (combine == false) return false;
  }
  return combine;
}
export function calculateComparators(command, record, functionParams) {
  var comparators = getComparatorFunctions();
  var parts = [];
  for (var op in comparators) {
    var comparator = comparators[op]
    if (comparator.type == "binary") {
      if (command.indexOf(comparator.symbol) != -1) {
        parts = command.split(comparator.symbol);
        return comparator.func(
          calculateLiteralOrVar(parts[0], record, functionParams),
          calculateLiteralOrVar(parts[1], record, functionParams)
        );
      }
    } else if (comparator.type == "unary") {
      if (command.indexOf(comparator.symbol) == 0) {
        return comparator.func(calculateLiteralOrVar(command.substring(1), record, functionParams), record)
      }
    }
  }
  // if (command[0]=="!") {
  //  var data =  calculateLiteralOrVar(command.substring(1), record);
  //  return data === null || data === "";
  // }
  // else {
  var data = calculateLiteralOrVar(command, record, functionParams);
  return data !== null && data !== "" && data !== undefined;
  // }
}
export function calculateLiteralOrVar(command, record, functionParams) {
  if (command === true || command === false) return command;
  if (!isNaN(command)) {
    return command;
  }
  if (command == "false") {
    return null;
  }
  if (command == "true") {
    return true;
  }
  if (command[0] == '"') {
    return command.replace(/["]+/g, '')
  }
  if (command[0] == "'") {
    return command.replace(/[']+/g, '')
  }
  var parts = command.split(".");
  var traversal = record;
  for (var i = 0; i < parts.length; i++) {
    var nextStep = parts[i]
    if (nextStep == "??function??") {
      var functionParam = functionParams[parts[++i]];
      traversal = callGenericFunction(traversal, functionParam);
    } else {
      traversal = traversal[nextStep];
    }
  }
  return traversal;
}
export function callGenericFunction(caller, functionParam) {
  var functionName = functionParam["name"];
  var parameter = functionParam["parameter"];
  var result;
  if (functionName == "oneMust") {
    result = false;
    for (var index in caller) {
      result |= calculateBrackets(parameter, caller[index]);
    }
  } else if (functionName == "allMust") {
    result = true;
    for (var index in caller) {
      result &= calculateBrackets(parameter, caller[index]);
    }
  } else if (functionName == "now") {
    var date = moment();
    result = date.format("YYYY-MM-DD")
  } else if (functionName == "date") {
    var value = caller[parameter];
    if (value) {
      result = moment(caller[parameter]).isValid()
    } else {
      result = false;
    }
  }
  if (result == 0) result = null;
  return result;
}
export function getComparatorFunctions() {
  var comparator = {};
  comparator["eq"] = {
    symbol: "==",
    type: "binary",
    func: function (val1, val2) {
      return val1 == val2;
    }
  };
  comparator["gteq"] = {
    symbol: ">=",
    type: "binary",
    func: function (val1, val2) {
      return val1 >= val2;
    }
  };
  comparator["lteq"] = {
    symbol: "<=",
    type: "binary",
    func: function (val1, val2) {
      return val1 <= val2;
    }
  };
  comparator["gt"] = {
    symbol: ">",
    type: "binary",
    func: function (val1, val2) {
      return val1 > val2;
    }
  };
  comparator["lt"] = {
    symbol: "<",
    type: "binary",
    func: function (val1, val2) {
      return val1 < val2;
    }
  };
  comparator["ne"] = {
    symbol: "!=",
    type: "binary",
    func: function (val1, val2) {
      return val1 != val2;
    }
  };
  comparator["plus"] = {
    symbol: "+",
    type: "binary",
    func: function (val1, val2) {
      if (!isNaN(val1) && !isNaN(val2)) {
        return val1 * 1 + val2 * 1;
      } else {
        return (val1 + "") + (val2 + "");
      }
    }
  };
  comparator["mult"] = {
    symbol: "*",
    type: "binary",
    func: function (val1, val2) {
      if (!isNaN(val1) && !isNaN(val2)) {
        return val1 * val2;
      } else {
        return false
      }
    }
  };
  comparator["not"] = {
    symbol: "!",
    type: "unary",
    func: function (val1) {
      return val1 === null || val1 === "";
    }
  };
  return comparator;
}
export function getDayDateString(dateString) {
  return dateString.substring(8, 10);
}
export function getMonthDateString(dateString) {
  return dateString.substring(5, 7);
}
export function getYearDateString(dateString) {
  return dateString.substring(0, 4);
}
export function unknownDayDateString(dateString) {
  return (getDayDateString(dateString) == "00");
}
export function unknownMonthDateString(dateString) {
  return (getMonthDateString(dateString) == "00");
}
