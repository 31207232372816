<template>
    <div class="socket" :title="data?.name"></div>
</template>

<script>
export default {
    props: ['data'],
    mounted() {
        // console.log(this.data)
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "./vars";

.socket {
    // position: absolute;
    display: inline-block;
    cursor: pointer;
    border: 1px solid grey;
    width: $socket-width;
    height: $socket-height;
    vertical-align: middle;
    background-image: linear-gradient(180deg, #fff, #ddd);
    z-index: 2;
    box-sizing: border-box;

    &:hover {
        background: #ddd;
    }

}

.input-socket .socket {
    border-radius: 50% 0 0 50%;
    margin-left: -$socket-width;
    margin-top: calc($socket-height / 8);
}

.output-socket .socket {
    border-radius: 0 50% 50% 0;
    margin-right: -$socket-width;
    margin-top: calc($socket-height / 8);
}
</style>